@font-face {
    font-family: 'Krungthep';
    font-style: normal;
    font-weight: 400;
    src: local('Krungthep'), url('./fonts/Krungthep.ttf') format('truetype');
}

@font-face {
    font-family: 'Inconsolata for Powerline';
    font-style: normal;
    font-weight: 400;
    src: local('Inconsolata for Powerline'), url('./fonts/Inconsolata for Powerline.otf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: local('Montserrat'), url('./fonts/Montserrat.otf') format('truetype');
}

html, body, #root {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
    position: relative;
}

#logoContainer {
    pointer-events: none;
    transition: opacity 1000ms linear;
}
